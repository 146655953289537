<template>
  <div>
    <div>
      档案编号：<Input
      :maxlength="20"
        v-model.trim="demandData.archiveDetailVo.archiveNum"
        style="width: 200px"
        :disabled="true"
      ></Input>
    </div>
    <Divider style="margin: 10px 0"/>
     <Form :model="demandData.archiveAssessVo" :inline="true" :label-colon="true" :disabled="true">
    <FormItem label="社工评估" style="width: 100%">
      <FormItem style="width: calc(100% - 54px);margin: 0 26px">
          <Checkbox v-model="demandData.archiveAssessVo.second" true-value="1" false-value="2" style="width: 100%;margin-bottom: 10px">
              二次探访
              <span v-if="demandData.archiveAssessVo.second == '1'">(进行二次探访的主要事项
            <Input :maxlength="20" v-model.trim="demandData.archiveAssessVo.secondItem"></Input>
            )
            </span>
            </Checkbox>
          <Checkbox v-model="demandData.archiveAssessVo.regular" true-value="1" false-value="2">
              定期探访/电话访问
          </Checkbox>
           <Select v-model="demandData.archiveAssessVo.regularCycle" style="width:200px" v-if="demandData.archiveAssessVo.regular=='1'">
                    <Option v-for="item in regularCycleList" :key="item.dictKey" :value="item.dictKey">{{item.dictValue}}</Option>
            </Select>
              <Checkbox v-model="demandData.archiveAssessVo.referral" true-value="1" false-value="2" style="width: 100%;">转介</Checkbox>
              <Checkbox v-model="demandData.archiveAssessVo.startCase" true-value="1" false-value="2" style="width: 100%;">开案（专业个案、咨询个案）</Checkbox>
              <Checkbox v-model="demandData.archiveAssessVo.team" true-value="1" false-value="2" style="width: 100%;">小组/活动</Checkbox>
              <Checkbox v-model="demandData.archiveAssessVo.follow" true-value="1" false-value="2" style="width: 100%;margin-bottom: 10px">
              不需要跟进<span v-if="demandData.archiveAssessVo.follow == '1'">（原因
            <Input :maxlength="20" v-model.trim="demandData.archiveAssessVo.followCause"></Input>
            )</span>
            </Checkbox>
         <FormItem label="危机因素" style="width: 100%;">
        <RadioGroup v-model="demandData.archiveAssessVo.crisis">
            <Radio label="2" style="margin-right: 20px">无</Radio>
            有
        </RadioGroup>
        <RadioGroup v-model="demandData.archiveAssessVo.crisisLevel">（<Radio label="3">高</Radio>
            <Radio label="2">中</Radio>
            <Radio label="1">低</Radio>
            ）</RadioGroup>
            <span v-if="demandData.archiveAssessVo.crisis == '1'">（请注明: <Input v-model="demandData.archiveAssessVo.crisisCause" style="width: calc(100% - 400px)"></Input>）
            </span>
        </FormItem>
      </FormItem>
    </FormItem>
     <FormItem label="处理跟进工作" style="width: 100%">
      <FormItem style="width: calc(100% - 54px);margin: 0 26px">
          <Checkbox v-model="demandData.archiveFollowVo.meetSelf" true-value="1" false-value="2">
              安排与案主会面/电话访问
            </Checkbox>
          <Checkbox v-model="demandData.archiveFollowVo.meetRelated" true-value="1" false-value="2" style="width: 100%;margin-bottom: 10px">
              安排与相关人员会面 
              <span v-if="demandData.archiveFollowVo.meetRelated == '1'">(请注明：
            <Input v-model="demandData.archiveFollowVo.meetRelatedDesc"></Input>
            )</span>
          </Checkbox>
          <Checkbox v-model="demandData.archiveFollowVo.delegateFollow" true-value="1" false-value="2" style="width: 100%;margin-bottom: 10px">
              委派其他社工跟进 
              <span v-if="demandData.archiveFollowVo.delegateFollow== '1'">(跟进人：
            <Input v-model="demandData.archiveFollowVo.followStaff"></Input>
            )</span>
          </Checkbox>
          <Checkbox v-model="demandData.archiveFollowVo.referral" true-value="1" false-value="2" style="width: 100%;margin-bottom: 10px">
              转介其他单位跟进 
              <span v-if="demandData.archiveFollowVo.referral== '1'">(请注明：
            <Input v-model="demandData.archiveFollowVo.referralDesc"></Input>
            )</span>
          </Checkbox>
          <Checkbox v-model="demandData.archiveFollowVo.followNot" true-value="1" false-value="2" style="width: 100%;margin-bottom: 10px">
              不适合跟进 
              <span v-if="demandData.archiveFollowVo.followNot== '1'">(原因：
            <Input v-model="demandData.archiveFollowVo.followNotDesc"></Input>
            )</span>
          </Checkbox>
      </FormItem>
     </FormItem>
  </Form>
  </div>
</template>

<script>
export default {
  props: ['demandData'],
  data() {
   return {regularCycleList:[]};
  },
   created() {
    this.$get('/datamsg/api/common/sys/findDictByDictType',{
      dictType: "REGULAR_CYCLE",
    }).then(res => {
      if(res.code == 200 && res.dataList) {
        this.regularCycleList = res.dataList;
      }else {
        this.$Message.error({
          background: true,
          content: res.desc
        })
      }
    })
  },
};
</script>

<style scoped lang="less">
.ivu-form-item {
  width: 100%;
  margin-bottom: 15px;
  .ivu-input-wrapper {
    width: calc(100% - 400px);
  }
}
/deep/input {
  border: 0;
  border-bottom: 1px solid #65656c;
  border-radius: 0;
  width: 100%;
}
/deep/input:hover,
/deep/input:focus {
  border: 0;
  border-bottom: 1px solid #65656c !important;
  box-shadow: none;
}
 /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled .ivu-checkbox-inner {
        background-color: #fff!important;
        color: #515a6e !important;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
      border: 1px solid #515a6e
    }
    /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,/deep/.ivu-radio-disabled .ivu-radio-inner:after {
      border-color: #515a6e;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner:after {
      background-color: #888;
    }
    /deep/.ivu-checkbox-disabled+span {
      color: #515a6e;
    }
</style>
